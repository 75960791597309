<template>
  <v-form>
    <div v-if="formErrors">
      <v-alert type="error"> Niet alle velden zijn goed ingevuld.</v-alert>
      <br />
    </div>

    <div
      v-if="
        checkoutStore.getOptions.alert &&
        checkoutStore.getOptions.alert.text &&
        checkoutStore.getOptions.alert.type
      "
    >
      <v-alert :type="checkoutStore.getOptions.alert.type" data-cy="top-alert">
        {{ checkoutStore.getOptions.alert.text }}
      </v-alert>
      <br />
    </div>

    <!--Name-->
    <div class="text-subtitle-1 text-medium-emphasis">Naam*</div>
    <v-text-field
      v-model="checkoutStore.order.name"
      data-cy="name"
      :error-messages="getVuelidateErrors(v$.name.$errors)"
      density="compact"
      placeholder="Naam"
      variant="outlined"
      @focusout="v$.name.$validate"
    />
    <!--Email-->
    <span v-if="checkoutStore.getOptions.toggleableFields.email.enabled">
      <div class="text-subtitle-1 text-medium-emphasis">
        {{
          "Emailadres" +
          (checkoutStore.getOptions.toggleableFields.email.required ? "*" : "")
        }}
      </div>
      <v-text-field
        v-model="checkoutStore.order.toggleableFields.email"
        data-cy="email"
        :error-messages="getVuelidateErrors(v$.toggleableFields.email.$errors)"
        density="compact"
        placeholder="Emailadres"
        type="email"
        variant="outlined"
        @focusout="v$.toggleableFields.email.$validate"
      />
    </span>

    <!--Telephone-->
    <span v-if="checkoutStore.getOptions.toggleableFields.telephone.enabled">
      <div class="text-subtitle-1 text-medium-emphasis">
        {{
          "Telefoonnummer" +
          (checkoutStore.getOptions.toggleableFields.telephone.required
            ? "*"
            : "")
        }}
      </div>
      <v-text-field
        v-model="checkoutStore.order.toggleableFields.telephone"
        data-cy="telephone"
        :error-messages="
          getVuelidateErrors(v$.toggleableFields.telephone.$errors)
        "
        density="compact"
        placeholder="Telefoonnummer"
        type="tel"
        variant="outlined"
        @focusout="v$.toggleableFields.telephone.$validate"
      />
    </span>

    <!-- Get location information -->
    <checkout-page-location-step />
    <template
      v-if="
        checkoutStore.locationVerified &&
        checkoutStore.getAvailableProducts.length == 0
      "
    >
      <v-alert type="error" data-cy="timeslots-full-alert">
        {{ $t("checkout.allTimeslotsFull") }}
      </v-alert>
    </template>

    <template v-else-if="checkoutStore.locationVerified">
      <template v-if="checkoutStore.locationFee != 0">
        <v-alert data-cy="location-fee-alert">
          Omdat u verder weg woont, moeten wij helaas extra kosten rekenen. Deze
          bedragen voor u
          {{ formatPrice(checkoutStore.locationFee) }}
        </v-alert>
        <br />
      </template>

      <!--Custom fields-->
      <checkout-page-checkout-form-custom-fields type="pre" />

      <!--Choosing a product / service-->
      <checkout-page-checkout-form-product-flow />

      <!--Custom fields-->
      <checkout-page-checkout-form-custom-fields type="post" />

      <!--        Donation, if enabled-->
      <span v-if="checkoutStore.getOptions.toggleableFields.donation.enabled">
        <div class="text-subtitle-1 text-medium-emphasis">
          {{
            "Donatie" +
            (checkoutStore.getOptions.toggleableFields.donation.required
              ? "*"
              : "")
          }}
        </div>
        <v-text-field
          v-model="donation"
          v-maska="donationMask"
          :error-messages="
            getVuelidateErrors(v$.toggleableFields.donation.$errors)
          "
          density="compact"
          hide-details="auto"
          single-line
          variant="outlined"
          @focusout="v$.toggleableFields.donation.$validate"
        >
          <template #prepend-inner> € </template>
        </v-text-field>
        <br />
      </span>

      <!--Customer remark, if enabled-->
      <span
        v-if="checkoutStore.getOptions.toggleableFields.customer_remark.enabled"
      >
        <div class="text-subtitle-1 text-medium-emphasis">Opmerkingen</div>
        <v-textarea
          v-model.lazy="checkoutStore.order.toggleableFields.customer_remark"
          :error-messages="
            getVuelidateErrors(v$.toggleableFields.customer_remark.$errors)
          "
          density="compact"
          hide-details="auto"
          placeholder="Opmerkingen"
          variant="outlined"
          @focusout="v$.toggleableFields.customer_remark.$validate"
        />
        <br />
      </span>

      <!--Choose payment method -->
      <!--Dont show if there is 1 payment method available -->
      <template v-if="checkoutStore.getOptions.paymentMethods.length > 1">
        <div class="text-subtitle-1 text-medium-emphasis">Betaalmethode</div>
        <v-select
          v-model="checkoutStore.order.paymentMethod"
          :items="checkoutStore.getOptions.paymentMethods"
          density="compact"
          item-title="label"
          item-value="id"
          placeholder="Betaalmethode"
          variant="outlined"
        />
      </template>

      <v-checkbox
        v-model="checkoutStore.order.privacy"
        data-cy="privacy"
        :error-messages="getVuelidateErrors(v$.privacy.$errors)"
        density="comfortable"
        hide-details="auto"
        @focusout="v$.privacy.$validate"
      >
        <template #label>
          <p>
            Ik ga akkoord met het
            <router-link target="_blank" to="/privacy"
              >privacy beleid
            </router-link>
            .*
          </p>
        </template>
      </v-checkbox>
      <v-checkbox
        v-model="checkoutStore.order.tos"
        data-cy="terms-of-service"
        :error-messages="getVuelidateErrors(v$.tos.$errors)"
        density="comfortable"
        hide-details="auto"
        @focusout="v$.tos.$validate"
      >
        <template #label>
          <p>
            Ik ga akkoord met het
            <router-link target="_blank" to="/voorwaarden"
              >algemene voorwaarden
            </router-link>
            .*
          </p>
        </template>
      </v-checkbox>
      <br />
      <div class="text-medium-emphasis font-weight-bold" data-cy="total-price">
        Totaalprijs: {{ checkoutStore.getTotalPrice }}
      </div>

      <br />

      <div v-if="formErrors">
        <v-alert type="error"> Niet alle velden zijn goed ingevuld.</v-alert>
        <br />
      </div>

      <template
        v-if="checkoutStore.getOptions.toggleableFields.hcaptcha.enabled"
      >
        <vue-hcaptcha
          ref="hcaptcha"
          :sitekey="checkoutStore.getOptions.hcaptchaSiteKey"
          language="nl-nl"
          @verify="onHCaptchaVerify"
        />
        <br />
        <span v-if="hCaptchaError">
          <v-alert
            title="Beep boop: Alleen mensen mogen een bestelling plaatsen. Bevestig alstublieft dat u een mens bent!"
            type="error"
          />
          <br />
        </span>
      </template>
      <div v-if="orderError">
        <v-alert type="error">
          {{ orderError }}
        </v-alert>
        <br />
      </div>

      <v-btn
        data-cy="submit-order"
        :loading="sendingOrder"
        color="success"
        size="large"
        @click="sendOrder"
        >Bestellen
      </v-btn>
    </template>
  </v-form>
</template>

<script lang="ts" setup>
// Declare google recaptcha variable for typescript
import useVuelidate from "@vuelidate/core";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import { vMaska } from "maska/vue";
import type { MaskInputOptions } from "maska";
import { klona } from "klona";
import { onMounted } from "vue";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import type { item } from "~/composables/store/checkout";

const checkoutStore = useCheckoutStore();
const { t } = useI18n();

const sendingOrder = ref<boolean>(false);
const orderError = ref<string>();

// Validation things
const v$ = useVuelidate(
  checkoutStore.getOrderValidationRules,
  checkoutStore.order,
);

const formErrors = ref(false);
const hCaptchaError = ref(false);

const config = useRuntimeConfig();

const donation = ref("0");
onMounted(() => {
  donation.value = (
    checkoutStore.order.toggleableFields.donation ?? "0"
  ).toString();
});

const donationMask = reactive<MaskInputOptions>({
  onMaska: (value) => {
    if (!isNaN(parseFloat(value.unmasked))) {
      checkoutStore.order.toggleableFields.donation = parseFloat(
        value.unmasked,
      );
    }
  },
  number: {
    locale: "nl",
    fraction: 2,
    unsigned: true,
  },
});

const verified = ref(false);

const hcaptcha = ref<VueHcaptcha>();

const onHCaptchaVerify = (tokenStr: string) => {
  verified.value = true;
  checkoutStore.order.toggleableFields.hcaptcha = tokenStr;
};

const sendOrder = async () => {
  formErrors.value = !(await v$.value.$validate());
  if (formErrors.value) {
    sendingOrder.value = false;
    return;
  } // Return early if we cant validate the input

  const order = klona(checkoutStore.getOrderDTO);
  sendingOrder.value = true;
  if (
    checkoutStore.getOptions.toggleableFields.hcaptcha.enabled &&
    !verified.value &&
    hcaptcha.value
  ) {
    const { response } = await hcaptcha.value.executeAsync();
    order.toggleableFields.hcaptcha = response;
  }

  await fetch(config.public.api_url + "/api/v1/order/checkout/data", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  }).then(async (data) => {
    const json = await data.json();
    if (data.status === 200) {
      document.location.href = json.redirectUrl;
    } else {
      sendingOrder.value = false;
      // json = json as { error_code: number, error_message: string, error_ident: any };
      const errorCode = json.error_code;
      if (errorCode === -403) {
        orderError.value =
          "Het door u gekozen tijdvak is helaas al vol, kies alstublieft een ander tijdvak.";
      } else if (errorCode === -407) {
        // We are out of some product. Show the user which product is out of stock.
        const outOfStockProduct =
          checkoutStore.getOptions.productOptions.products.find(
            (product) => product.id === json.error_ident.product,
          ) as item;
        orderError.value = t("checkout.outOfStock", {
          product: outOfStockProduct.label,
          unitLabel: outOfStockProduct?.unitLabel,
          pluralUnitLabel: outOfStockProduct?.pluralUnitLabel,
        });
      } else {
        orderError.value =
          json.error_code +
          ": Er is iets misgegaan maar we weten niet precies wat. Probeer het opnieuw of neem telefonisch contact op. Onze excuses voor het ongemak.";
      }
    }
  });
};
</script>

<style scoped></style>
