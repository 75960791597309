<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import type { LocationInformation } from "~/components/CheckoutPage/LocationStep.vue";
import type { PickupPoint } from "~/composables/store/checkout";

const checkoutStore = useCheckoutStore();

const emit = defineEmits<{
  setLocationInformation: [value: LocationInformation];
}>();

const v$ = useVuelidate(
  checkoutStore.getDeliveryOptionsValidationRules,
  checkoutStore.order.fulfillment,
);

const pickupPoint = ref<PickupPoint>();

const updatePickupPoint = () => {
  if (checkoutStore.order.fulfillment.deliver) {
    return;
  }

  if (!pickupPoint.value) {
    return;
  }

  checkoutStore.order.fulfillment.data.pickupPoint = pickupPoint.value.id;

  emit("setLocationInformation", {
    locationFee: pickupPoint.value.fee,
    products: pickupPoint.value?.products.map((product) => ({
      id: product.id,
      price: product.price,
      timeslots: product.timeslots,
    })),
  });
};
</script>
<template>
  <div class="text-subtitle-1 text-medium-emphasis">Afhaalpunt</div>
  <v-select
    v-model="pickupPoint"
    :items="checkoutStore.getOptions.pickupPoints"
    item-title="label"
    density="compact"
    return-object
    placeholder="Afhaalpunt"
    data-cy="pickup-selector"
    variant="outlined"
    :error-messages="getVuelidateErrors(v$.data.pickupPoint.$errors)"
    @update:model-value="updatePickupPoint"
  >
    <template #item="{ props, item }">
      <v-list-item v-bind="props" :subtitle="item.raw.description" />
    </template>
  </v-select>
</template>

<style scoped></style>
