<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { useDisplay } from "vuetify";
import { vMaska } from "maska/vue";
import type { MaskInputOptions } from "maska";
import { getVuelidateErrors } from "~/composables/errorHandler";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import type { LocationInformation } from "~/components/CheckoutPage/LocationStep.vue";

const checkoutStore = useCheckoutStore();
const unsupportedLocationError = ref(false);
const streetName = ref("");
const postalCodeLoading = ref(false);

const { smAndUp } = useDisplay();

const v$ = useVuelidate(
  checkoutStore.getDeliveryOptionsValidationRules,
  checkoutStore.order.fulfillment,
);

const emit = defineEmits<{
  setLocationInformation: [value: LocationInformation];
}>();

const config = useRuntimeConfig();

let timer: number | undefined;
const debounceUpdateLocationInformation = () => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(updateLocationInformation, 500) as unknown as number;
};

const updateLocationInformation = async () => {
  if (!checkoutStore.order.fulfillment.deliver) {
    return;
  }
  // TODO: Location is changed, reset form if needed

  checkoutStore.locationVerified = false;
  streetName.value = "";
  unsupportedLocationError.value = false;
  checkoutStore.locationFee = 0;
  const deliveryDataDto = checkoutStore.order.fulfillment.data;
  const postalCode: RegExpMatchArray | null = (deliveryDataDto.postalCode ?? "")
    .replaceAll(" ", "")
    .match(/[0-9]{4}([a-zA-Z]{2})/g);
  const houseNumber = (deliveryDataDto.houseNumber ?? "")
    .replaceAll(" ", "")
    .match(/[0-9]{1,3}([a-zA-Z]*)/g);

  // Check if the postal code is valid
  if (!postalCode || !houseNumber) {
    return;
  }

  postalCodeLoading.value = true;
  await fetch(
    config.public.api_url +
      "/api/v1/address/lookup?" +
      new URLSearchParams({
        postalCode: postalCode[0],
        houseNumber: houseNumber[0],
      }),
  )
    .then((response) => {
      if (response.ok) return response.json();
      else {
        throw response.json();
      }
    })
    .then((data) => {
      streetName.value =
        data.street +
        " " +
        data.houseNumber +
        data.houseNumberSuffix +
        " te " +
        data.city;

      postalCodeLoading.value = false;

      // If there is no area code, the location is not supported
      if (data.areaId === undefined) {
        unsupportedLocationError.value = true;
        return;
      }
      // Update prices, timeslots and location fee
      emit("setLocationInformation", {
        locationFee: data.locationFee,
        products: data.products,
      });
    })
    .catch(async (error) => {
      const data = await error;
      postalCodeLoading.value = false;
      if (data.error_code === -908) {
        streetName.value = "Adres niet gevonden.";
      }
    });
};

const postalCodeMask = reactive<MaskInputOptions>({
  mask: "####AA",
  tokens: {
    A: { pattern: /[A-Z]/, transform: (chr: string) => chr.toUpperCase() },
  },
});

const houseNumberMask = reactive<MaskInputOptions>({
  mask: "#AAA",
  tokens: {
    "#": { pattern: /[0-9]/, multiple: true },
    A: {
      pattern: /[A-Z]/,
      transform: (chr: string) => chr.toUpperCase(),
      optional: true,
    },
  },
});
</script>

<template>
  <v-container
    v-if="checkoutStore.order.fulfillment.deliver"
    class="pa-0"
    fluid
  >
    <div class="text-subtitle-1 text-medium-emphasis">Adresgegevens</div>
    <v-row>
      <v-col cols="7" sm="4">
        <v-text-field
          v-model.lazy="checkoutStore.order.fulfillment.data.postalCode"
          v-maska="postalCodeMask"
          :error-messages="getVuelidateErrors(v$.data.postalCode.$errors)"
          data-cy="postal-code"
          density="compact"
          hide-details="auto"
          placeholder="Postcode"
          prepend-inner-icon="mdi-map-marker"
          variant="outlined"
          @focusout="v$.data.postalCode.$validate"
          @update:model-value="debounceUpdateLocationInformation"
        />
      </v-col>
      <v-col cols="5" sm="3">
        <v-text-field
          v-model="checkoutStore.order.fulfillment.data.houseNumber"
          v-maska="houseNumberMask"
          :error-messages="getVuelidateErrors(v$.data.houseNumber.$errors)"
          data-cy="house-number"
          density="compact"
          hide-details="auto"
          :placeholder="smAndUp ? 'Huisnummer' : 'Huisnr.'"
          variant="outlined"
          @focusout="v$.data.houseNumber.$validate"
          @update:model-value="debounceUpdateLocationInformation"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="streetName"
          :loading="postalCodeLoading"
          data-cy="address"
          density="compact"
          placeholder="Straat - automatisch aangevuld"
          readonly
          variant="outlined"
        />
      </v-col>
    </v-row>
  </v-container>
  <template v-if="unsupportedLocationError">
    <v-alert type="error" data-cy="unsupported-location-alert">
      {{ $t("checkout.unsupportedLocation") }}
    </v-alert>
    <br />
  </template>
</template>

<style scoped></style>
